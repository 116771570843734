import { ClientLanguage } from 'utils/language';

const FALLBACK_LANGUAGE_LABEL = '?';

export interface Option {
  value: ClientLanguage;
  label: string;
}

export const options: Option[] = [
  {
    label: 'RU',
    value: ClientLanguage.Russian,
  },
  {
    label: 'EN',
    value: ClientLanguage.English,
  },
  {
    label: 'ES',
    value: ClientLanguage.Spanish,
  },
  {
    label: 'TH',
    value: ClientLanguage.Thai,
  },
  {
    label: 'AR',
    value: ClientLanguage.Arabic,
  },
];

export function getActiveLabel(language: ClientLanguage | undefined): string {
  if (!language) {
    return FALLBACK_LANGUAGE_LABEL;
  }

  const option = options.find(({ value }) => value === language);
  if (!option) {
    return FALLBACK_LANGUAGE_LABEL;
  }

  return option.label;
}
