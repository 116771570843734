import React, { useState, useRef, useCallback } from 'react';

import cx from 'classnames';

import { List } from 'components/List';
import { Text } from 'components/Text';
import { ClientLanguage } from 'utils/language';
import { useOnClickOutside } from 'utils/main';

import { getActiveLabel, Option, options } from './helpers';

import css from './NavigationFooterLanguage.module.css';

export interface NavigationFooterLanguageProps {
  className?: string;
  dotsClassName?: string;

  language: ClientLanguage | undefined;
  onClickLanguageSelect?: (event: React.MouseEvent) => void;
  onRequestChangeLanguage?: (language: ClientLanguage) => void;
}
export const NavigationFooterLanguage = React.memo<NavigationFooterLanguageProps>(
  ({
    language,
    className,
    dotsClassName,
    // langClassName,
    onClickLanguageSelect = () => undefined,
    onRequestChangeLanguage = () => undefined,
  }) => {
    const moreInfoRef = useRef<HTMLDivElement>(null);
    const [isListOpen, setIsListOpen] = useState<boolean>(false);

    useOnClickOutside(moreInfoRef, () => setIsListOpen(false));

    const renderItem = (item: Option) => (
      <div className={css.listItem}>{item.label}</div>
    );

    const handleSelectLanguage = useCallback(
      (option: Option) => {
        setIsListOpen(false);
        onRequestChangeLanguage(option.value);
      },
      [onRequestChangeLanguage],
    );

    return (
      <div ref={moreInfoRef} className={cx(css.moreInfo, className)}>
        <div
          className={cx(css.dots, { [css.activeInfo]: isListOpen }, dotsClassName)}
          onClick={event => {
            onClickLanguageSelect(event);
            if (event.defaultPrevented) {
              return;
            }
            setIsListOpen(!isListOpen);
          }}
        >
          <Text.R5>{getActiveLabel(language)}</Text.R5>
        </div>

        <List
          className={cx(css.list, { [css.listOpen]: isListOpen })}
          items={options}
          renderItem={renderItem}
          onSelectItem={handleSelectLanguage}
        />
      </div>
    );
  },
);
