import React, { useCallback, useMemo } from 'react';

import { ALL_GAME_ID, api } from 'api';

import { Icon } from 'components/Icons';
import { useText } from 'components/Language';
import { Text } from 'components/Text';
import { getCurrentLocationGame } from 'utils/links';

import { NavigationFooter, NavigationFooterProps } from '../NavigationFooter';
import { NavigationItem, NavigationItemGame } from '../NavigationItem';
import { NavigationLogo } from '../NavigationLogo';

import * as helpers from './helpers';

import css from './Navigation.module.css';

import type { SupportedGameId } from 'api/types';
import type { ParsedUrlQuery } from 'querystring';

export interface NavigationProps extends NavigationFooterProps {
  isAuthed: boolean;
  myGames: api.GameId[];

  pathname: string;
  query: ParsedUrlQuery;

  onLogoClick?: (event: React.MouseEvent) => void;
  onItemClick?: (tab: helpers.NavigationPage, event: React.MouseEvent) => void;
  onGameClick?: (
    game: SupportedGameId,
    event: React.MouseEvent,
    isMyGame: boolean,
  ) => void;
}

export const Navigation = React.memo<NavigationProps>(
  ({
    isAuthed,
    myGames,

    pathname = '/',

    onGameClick = () => undefined,
    onLogoClick = () => undefined,
    onItemClick = () => undefined,

    ...footerProps
  }) => {
    const text = useText(state => state.controls.navigation);

    const activePage = helpers.getActivePage(pathname);
    const activeGame = getCurrentLocationGame(pathname);

    // Preserving order and filtering out unsupported games in web
    const myFilteredGames = useMemo(() => {
      return ALL_GAME_ID.filter(game => myGames.includes(game));
    }, [myGames]);

    // all supported games minus myGames
    const unaddedGames = useMemo(() => {
      return ALL_GAME_ID.filter(game => !myGames.includes(game));
    }, [myGames]);

    const renderGamesNav = useCallback(
      (title: string, games: SupportedGameId[]) => {
        if (games.length === 0) {
          return null;
        }

        return (
          <menu className={css.tabsContainer}>
            <Text.R3Memo semibold className={css.menuTitle}>
              {title}
            </Text.R3Memo>

            {games.map(game => (
              <NavigationItemGame
                key={game.toString()}
                game={game}
                isActive={game === activeGame}
                onClick={event => onGameClick(game, event, myGames.includes(game))}
              />
            ))}
          </menu>
        );
      },
      [onGameClick, activeGame],
    );

    return (
      <header className={css.container}>
        <NavigationLogo className={css.logoContainer} onClick={onLogoClick} />
        <menu className={css.tabsContainer}>
          <NavigationItem
            Icon={Icon.Home}
            isActive={activePage === 'home'}
            link={helpers.indexRoute}
            onClick={event => onItemClick('home', event)}
          >
            {text.home}
          </NavigationItem>
          {/* {isAuthed ? (
            <NavigationItem
              Icon={Icon.Chats}
              isActive={activePage === 'chats'}
              link={helpers.chatsRoute}
              onClick={event => onItemClick('chats', event)}
            >
              {text.chats}
            </NavigationItem>
          ) : null} */}
          {isAuthed ? (
            <NavigationItem
              Icon={Icon.MyGroups}
              isActive={activePage === 'myGroups'}
              link={helpers.myGroupsRoute}
              onClick={event => onItemClick('myGroups', event)}
            >
              {text.myGroups}
            </NavigationItem>
          ) : null}
        </menu>
        <div className={css.gamesNav}>
          {renderGamesNav(text.yourGames, myFilteredGames)}
          {renderGamesNav(text.lfgGames, unaddedGames)}
        </div>
        <NavigationFooter {...footerProps} />
      </header>
    );
  },
);
