import React from 'react';

import { useModal } from 'components/ContextModal';
import { Icon } from 'components/Icons';
import { Planet } from 'components/Icons/Icons';
import { useText } from 'components/Language';
import { MoreInfo } from 'components/MoreInfo';
import { Text } from 'components/Text';
import { SocialMedia, SOCIAL_MEDIA_LINKS } from 'const';
import { dynamic } from 'utils/dynamic';
import { ClientLanguage } from 'utils/language';

import { ButtonScrollToTop } from '../ButtonScrollToTop';
import { NavigationFooterLanguage } from '../NavigationFooterLanguage';

import { FooterLink, FooterLinkOption } from './helpers';

import css from './NavigationFooter.module.css';

const ModalClusterRegion = dynamic(() =>
  import('containers/ModalClusterRegion').then(mod => mod.ModalClusterRegion),
);

export interface NavigationFooterProps {
  onClickFooterLink?: (link: FooterLink, event: React.MouseEvent) => void;
  onClickFooterSocialMedia?: (media: SocialMedia, event: React.MouseEvent) => void;
  onClickLanguageSelect?: (event: React.MouseEvent) => void;

  onRequestChangeLanguage?: (language: ClientLanguage) => void;
  language: ClientLanguage | undefined;
}

export const NavigationFooter = React.memo<NavigationFooterProps>(
  ({
    onClickFooterLink = () => undefined,
    onClickFooterSocialMedia = () => undefined,
    ...props
  }) => {
    const modal = useModal();
    const text = useText(state => state.controls.footer);

    const links: FooterLinkOption[] = [
      { label: text.menu.addBot, url: `https://lf.group/bot/invite`, type: 'bot' },
      { label: text.menu.about, url: `https://lf.group/about`, type: 'about' },
      { label: text.menu.tos, url: 'https://lf.group/terms', type: 'tos' },
      { label: text.menu.privacy, url: 'https://lf.group/privacy', type: 'privacy' },
    ];

    const renderLink = (link: FooterLinkOption): React.ReactNode => {
      return (
        <a
          className={css.commonLink}
          href={link.url}
          rel="noopener noreferrer"
          target="_blank"
          onClick={event => onClickFooterLink(link.type, event)}
        >
          <Text.R2>{link.label}</Text.R2>
        </a>
      );
    };

    return (
      <footer className={css.container}>
        <ButtonScrollToTop />
        <div>
          <Text.Subtitle className={css.label}>{text.joinChannels}</Text.Subtitle>

          <div className={css.socialLinks}>
            <div className={css.rightSection}>
              <SocialLink
                className={css.smallIcon}
                Icon={Icon.Discord}
                link={SOCIAL_MEDIA_LINKS.Discord}
                onClick={event => onClickFooterSocialMedia('Discord', event)}
              />
              <SocialLink
                className={css.smallIcon}
                Icon={Icon.Twitter}
                link={SOCIAL_MEDIA_LINKS.Twitter}
                onClick={event => onClickFooterSocialMedia('Twitter', event)}
              />
              <div className={css.iconContainer}>
                <button
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    modal.open(ModalClusterRegion.Component, {});
                  }}
                  onMouseOver={() => {
                    ModalClusterRegion.prefetch();
                  }}
                >
                  <Planet
                    className={css.smallIcon}
                    style={{ width: 14, height: 14 }}
                    // onClick={() => modal.open(ModalClusterRegion, {})}
                  />
                </button>
              </div>
              <NavigationFooterLanguage className={css.language} {...props} />
              <MoreInfo
                className={css.moreInfo}
                items={links}
                renderItem={renderLink}
              />
            </div>
          </div>
        </div>
      </footer>
    );
  },
);

interface SocialLinkProps {
  link: string;
  Icon: React.FC<Icon.IconProps>;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
}
export const SocialLink = React.memo<SocialLinkProps>(
  ({ link, Icon, className, onClick = () => undefined }) => {
    return (
      <a
        className={css.iconContainer}
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onClick}
      >
        <Icon className={className} />
      </a>
    );
  },
);
