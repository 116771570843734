import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { GameIcon } from 'components/Icons__deprecated';
import { useTranslations } from 'components/Language';
import { Text } from 'components/Text';
import { links } from 'utils/links';

import css from './NavigationItem.module.css';

import type { SupportedGameId } from 'api/types';

export interface NavigationItemGameProps {
  isActive?: boolean;
  game: SupportedGameId;
  onClick?: (event: React.MouseEvent) => void;
}

export const NavigationItemGame = React.memo<NavigationItemGameProps>(
  ({ game, onClick, isActive = false }) => {
    const text = useTranslations();

    return (
      <Link
        className={cx(css.container, { [css.containerActive]: isActive })}
        href={links.groups(game)}
        onClick={onClick}
      >
        <GameIcon className={css.itemIcon} gameId={game} size={32} />
        <Text.R2Memo className={css.itemText}>
          {text.gameTitle(game).short}
        </Text.R2Memo>
      </Link>
    );
  },
);
