import React, { useState } from 'react';

import cx from 'classnames';

import { Icon } from '../Icons';
import { LoaderBouncing } from '../Loaders';
import { Text } from '../Text';

import css from './Button.module.css';

export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'green'
  | 'green-10'
  | 'black'
  | 'transparent'
  | 'dark-12'
  | 'red';

export interface ButtonProps {
  type?: 'submit' | 'reset' | 'button';
  onClick?: (event: React.MouseEvent) => unknown | Promise<unknown>;
  onMouseOver?: (event: React.MouseEvent) => unknown | Promise<unknown>;

  disabled?: boolean;

  color?: ButtonColor;
  className?: string;
  iconClassName?: string;
  labelClassName?: string;
  style?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  fullWidth?: boolean;

  loading?: boolean;

  Icon?: Icon.IconType;
  children?: React.ReactText | React.ReactElement;
  form?: string;
}

export const Button = React.memo<ButtonProps>(
  ({
    children = 'Continue',

    type = 'button',
    onClick = () => undefined,
    onMouseOver = () => undefined,

    disabled = false,

    color = 'primary',
    className,
    iconClassName,
    labelClassName,
    style = {},
    styleContainer = {},
    loading = false,

    Icon,
    form,
  }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function renderLoading(): React.ReactNode {
      const getColor = () => {
        switch (color) {
          case 'primary':
          case 'green':
            return 'var(--dark)';
          case 'black':
            return 'var(--green)';
          default:
            return 'var(--white)';
        }
      };

      return (
        <div
          className={css.loadingContainer}
          style={loading || isLoading ? { display: 'flex', opacity: 1 } : {}}
          onClick={event => event.stopPropagation()}
        >
          <LoaderBouncing color={getColor()} size={20} />
        </div>
      );
    }

    function renderIcon(): React.ReactNode {
      if (!Icon) {
        return null;
      }

      return <Icon className={cx(css.icon, iconClassName)} />;
    }

    return (
      <button
        className={cx(
          css.container,
          {
            [css.colorPrimary]: color === 'primary',
            [css.colorSecondary]: color === 'secondary',
            [css.colorGreen]: color === 'green',
            [css.colorGreen10]: color === 'green-10',
            [css.colorBlack]: color === 'black',
            [css.colorTransparent]: color === 'transparent',
            [css.colorDark12]: color === 'dark-12',
            [css.colorRed]: color === 'red',
            [css.disabled]: disabled,
          },
          className,
        )}
        disabled={disabled}
        form={form}
        style={style}
        type={type}
        onClick={async event => {
          if (isLoading) {
            return;
          }
          if (disabled) {
            return;
          }
          try {
            const response = onClick(event);
            if (response instanceof Promise) {
              setIsLoading(true);
              await response;
            }
          } finally {
            setIsLoading(false);
          }
        }}
        onMouseOver={onMouseOver}
      >
        {renderIcon()}
        <Text.R3
          semibold
          className={cx(css.label, labelClassName)}
          style={{ opacity: loading || isLoading ? 0 : 1, ...styleContainer }}
        >
          {children}
        </Text.R3>
        {renderLoading()}
      </button>
    );
  },
);
