import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { IconProps } from 'components/Icons';

import css from './NavigationLogo.module.css';

interface LogoLinkProps {
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}

export const NavigationLogo = React.memo<LogoLinkProps>(
  ({ onClick = () => undefined, className }) => {
    // const me = useMe();

    return (
      <Link
        className={cx(css.logo, className)}
        href={{ pathname: '/' }}
        onClick={event => {
          // filtersIndex(getDefaultFilters(me, lang.apiValue));
          onClick(event);
        }}
      >
        <Logo />
      </Link>
    );
  },
);

export const Logo = React.memo<IconProps>(props => (
  <svg
    fill="url(#paint0_linear_925_4914)"
    height={24}
    viewBox="0 0 39 24"
    width={39}
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M0 0H39V24H0V0ZM1.59836 1.59574V22.4043H14.9607V16.5319H7.48033V1.59574H1.59836ZM9.07869 1.59574V7.46809H16.2941V9.06383H9.07869V14.9362H16.559V22.4043H22.441V1.59574H9.07869ZM24.0393 1.59574V22.4043H37.4016V9.06383H31.5197V14.9362H32.8577V16.5319H28.5833V14.9362H29.9213V7.46809H37.4016V1.59574H24.0393Z"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_925_4914"
        x1={0}
        x2={39.1}
        y1={0}
        y2={22.6}
      >
        <stop stopColor="#24F24D" />
        <stop offset={0.1} stopColor="#26F24F" />
        <stop offset={0.1} stopColor="#2CF354" />
        <stop offset={0.2} stopColor="#36F35C" />
        <stop offset={0.27} stopColor="#45F467" />
        <stop offset={0.33} stopColor="#57F576" />
        <stop offset={0.4} stopColor="#6DF688" />
        <stop offset={0.47} stopColor="#85F89C" />
        <stop offset={0.53} stopColor="#9EF9B0" />
        <stop offset={0.6} stopColor="#B6FBC4" />
        <stop offset={0.7} stopColor="#CCFCD6" />
        <stop offset={0.7} stopColor="#DFFDE5" />
        <stop offset={0.8} stopColor="#EDFEF0" />
        <stop offset={0.87} stopColor="#F7FFF9" />
        <stop offset={0.9} stopColor="#FDFFFD" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
));
