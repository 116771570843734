import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { Text } from 'components/Text';

import css from './NavigationItem.module.css';

export interface NavigationItemProps {
  link: string;
  isActive?: boolean;
  children: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: (event: React.MouseEvent) => void;
}

export const NavigationItem = React.memo<NavigationItemProps>(
  ({ children, link, Icon, onClick, isActive = false }) => {
    return (
      <Link
        className={cx(css.container, { [css.containerActive]: isActive })}
        href={{ pathname: link }}
        onClick={onClick}
      >
        {Icon ? <Icon className={css.itemIcon} /> : null}
        <Text.R2Memo className={css.itemText}>{children}</Text.R2Memo>
      </Link>
    );
  },
);
