import React, { useCallback, useRef, useState } from 'react';

import cx from 'classnames';

import { Icon } from '../../../components/Icons';
import { useText } from '../../../components/Language';

import css from './HeaderSearch.module.css';

export interface HeaderSearchProps {
  defaultValue?: string;
  onRequestSearch: (value: string, event: React.FormEvent) => Promise<void> | void;
  onChange?: (value: string) => void;
  animated?: boolean;

  placeholder?: string;
}

export const HeaderSearch: React.FC<HeaderSearchProps> = ({
  defaultValue = '',
  animated = true,
  placeholder,
  onRequestSearch,
  onChange,
}) => {
  const text = useText(state => state.home.pasha.search);

  const ref = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<string>(defaultValue);
  const [isFocused, setIsFocused] = useState<boolean>(Boolean(defaultValue));

  const handleFormSubmit = useCallback(
    async (event: React.FormEvent) => {
      // event.preventDefault();

      const result = await onRequestSearch(value, event);

      // persist custom behaviour after callback handling event
      if (!event.defaultPrevented) {
        event.preventDefault();
      }

      if (typeof result === 'string') {
        setValue(result as string);
      }
    },
    [value],
  );
  const handleFormClick = useCallback(() => {
    ref?.current?.focus();
  }, []);

  const handleInputFocus = useCallback(() => setIsFocused(true), []);
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }

      setValue(event.target.value);
    },
    [onChange],
  );
  const handleInputBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setIsFocused(false);
    }
  }, []);

  const handleCancelClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (onChange) {
        onChange('');
      }
      setValue('');
    },
    [onChange],
  );

  return (
    <form
      className={cx(css.container, { [css.inputLong]: !animated || isFocused })}
      onClick={handleFormClick}
      onSubmit={handleFormSubmit}
    >
      <input
        ref={ref}
        className={css.inputContainer}
        placeholder={placeholder || text.placeholder}
        value={value}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
      />
      <Icon.Search className={css.icon} />

      <Icon.Cancel
        className={cx(css.closeContainer, { [css.closeHidden]: value === '' })}
        onClick={handleCancelClick}
      />
    </form>
  );
};
