export type NavigationPage = 'home' | 'myGroups' | 'chats';

export const indexRoute = '/';
export const myGroupsRoute = '/my';
export const playersRoute = '/players';
export const chatsRoute = '/msg';

export function getActivePage(pathname: string): NavigationPage | null {
  switch (pathname) {
    case indexRoute:
      return 'home';
    case myGroupsRoute:
      return 'myGroups';
    case chatsRoute:
      return 'chats';
    default:
      return null;
  }
}
