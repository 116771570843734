import React, { useState } from 'react';

import cx from 'classnames';

import { Icon } from '../Icons';
import { LoaderBouncing } from '../Loaders';

import css from './Button.module.css';

export interface ButtonIconProps {
  Icon: Icon.IconType;

  disabled?: boolean;
  style?: React.CSSProperties;
  loading?: boolean;
  className?: string;
  iconClassName?: string;
  onClick?: (event: React.MouseEvent) => void;
  onPointerEnter?: (event: React.PointerEvent<HTMLButtonElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ButtonIcon = React.memo<ButtonIconProps>(
  ({
    Icon,
    loading,
    className,
    iconClassName,
    onPointerEnter,
    onClick = () => undefined,
    onMouseOver,
    disabled,
    ...props
  }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const renderLoading = (): React.ReactNode => {
      return (
        <div
          className={cx(css.loadingContainer, css.loadingIcon)}
          style={isLoading || loading ? { display: 'flex', opacity: 1 } : {}}
          onClick={event => event.stopPropagation()}
        >
          <LoaderBouncing color="var(--white)" size={20} />
        </div>
      );
    };

    return (
      <button
        className={cx(css.buttonIconContainer, className)}
        disabled={disabled || isLoading || loading}
        type="button"
        onClick={async event => {
          setIsLoading(true);
          try {
            await onClick(event);
          } catch {}
          setIsLoading(false);
        }}
        onMouseOver={onMouseOver}
        onPointerEnter={event => {
          if (onPointerEnter) {
            onPointerEnter(event);
          }
        }}
        {...props}
      >
        <Icon
          className={cx(css.buttonIcon, iconClassName)}
          style={{ opacity: loading ? 0 : 1 }}
        />
        {renderLoading()}
      </button>
    );
  },
);
